.header {
  width: 100%;
  background-color:white;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.logo img {
  height: 80px;
}

.nav {
  display: flex;
  justify-content: flex-end; /* Aligns navigation links to the right */
  flex-grow: 1; /* Allow nav to take available space */
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin-left:auto; /* Pushes nav links to the right*/
}

.nav-links li {
  display: inline;
}

.nav-links a {
  color: black;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.nav-links a:hover {
  background-color: greenyellow;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .nav {
    display: none;
  }

  .header.active .nav {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .nav-links {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .nav-links a {
    padding: 10px;
    width: 100%;
    text-align: center;
  }

  .menu-toggle {
    display: block;
    cursor: pointer;
  }

  .menu-toggle .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: black;
    transition: all 0.3s;
  }
}

/* Flexbox Container to Ensure Centered Content */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}




  