.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align:justify;
    padding: 20px;
  }
  
  .page h1 {
    margin-bottom: 5px;
  }
  
  .page p {
    max-width: 600px;
    margin-bottom: 20px; /* Add margin-bottom to create space between paragraphs */
  }
  
  .image {
    border-radius: 15px; /* Adjust the value to get the desired roundness */
  }

  .spaced-list {
    margin-top: 20px; /* Add top margin to create space between the image and the list */
    padding-left: 20px; /* Add left padding to align the list correctly */
  }
  
  .spaced-list li {
    margin-bottom: 10px; /* Add bottom margin to each list item for spacing */
  }

  .plan-button {
    display: inline-block;
    margin-top: 20px; /* Add top margin to create space between the list and the button */
    padding: 10px 20px;
    background-color: #007bff; /* Button background color */
    color: #fff; /* Button text color */
    text-align: center;
    text-decoration: none;
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition */
  }
  
  .plan-button:hover {
    background-color: #0056b3; /* Change background color on hover */
  }

  .donation-button img {
    width: 200px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
  }
  