.home {
    display: flex;
    /* align-items: center; */ 
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap; /* Ensures content wraps on smaller screens */
  }
  
  .home-image {
    flex: 1;
    min-width: 300px; /* Minimum width for the image container */
    max-width: 500px; /* Maximum width for the image container */
    text-align: center;
  }
  
  .home-image img {
    width: 100%;
    height: auto;
  }
  
  .home-content {
    flex: 2;
    min-width: 300px; /* Minimum width for the content container */
    padding: 20px;
    text-align: left; /* Align text to the left */
  }
  
  @media (max-width: 768px) {
    .home {
      flex-direction: column;
    }
  
    .home-content {
      text-align: center;
    }
  }
  